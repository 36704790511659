
    @use '@variables_common' as *;
    
//他ファイルから読み込まれるscssファイルのみ@variables_common明示的にimport(それ以外はwebpackで自動挿入される)
@use '@variables_common' as *;
@use '@variables_iqfu' as *;

.dialog {
  &.scroll {
    :global(.MuiDialog-paper) {
      overflow-y: scroll;
    }
  }

  :global(.MuiDialog-paper) {
    @include media(lg) {
      width: 100%;
      max-width: calc(100% - 32px);
      max-height: calc(100% - 128px);
      margin: 0;
    }
  }
}

.dialog_inner {
  padding: 48px;
  text-align: center;
}

.close_dialog_button {
  position: sticky;
  top: 4px;
  right: 4px;
  width: 40px;
  height: 40px;
  margin-left: auto;
  cursor: pointer;

  @include media(lg) {
    width: 24px;
    height: 24px;
  }
}
