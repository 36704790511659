
    @use '@variables_common' as *;
    
@use '@variables_iqfu' as *;

.login_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  //ヘッダーを除く
  min-height: calc(100vh - 55px);
  padding: 24px;
  background-image: url('/images/iqfu/login_background.svg');
  background-repeat: no-repeat;
  background-size: cover;

  @include media(lg) {
    padding: 20px;
  }
}

.login_wrapper {
  display: flex;
  flex-direction: column;
  gap: 36px 0;
  align-items: center;
  justify-content: center;

  @include media(lg) {
    width: 100%;
  }
}

.service_logo {
  width: 200px;
  margin-bottom: 32px;

  @include media(sm) {
    width: 160px;
  }
}

.login_card {
  width: 500px;
  padding: 40px 32px 0;
  text-align: center;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);

  @include media(lg) {
    width: 100%;
  }

  @include media(sm) {
    padding: 40px 20px 0;
  }
}

.login_card_title_admin {
  position: absolute;
  top: 0.5em;
  left: 50%;
  font-size: min(2rem, 20px);
  font-weight: bold;
  transform: translateX(-50%);

  @include media(sm) {
    position: static;
    margin-bottom: 1em;
    transform: none;
  }
}

.form {
  margin-top: 20px;
}

.input_wrap {
  margin-bottom: 16px;
}

.input {
  width: 100%;
  max-width: 100%;
  height: 48px;
  text-align: left;
}

.checkbox_row {
  line-height: 20px;
  color: #333;
}

.checkbox {
  width: 14px;
  height: 14px;
  margin: 0 5px 2px 0;
  border: 1px solid #ccc;
}

.login_button {
  display: block;
  width: 300px;
  padding: 1em 0;
  margin: 20px auto 0;
  margin-bottom: 16px;
  font-size: min(1.6rem, 16px);
  line-height: 1;
  color: #fff;
  text-align: center;
  background: $public_button_green;
  border-radius: 90px;

  a {
    color: $white;
  }

  @include hover_color($public_button_hover_green);

  @include media(sm) {
    width: 88%;
  }
}

.line_login {
  padding: 40px 0;
  border-top: 1px solid #ccc;
}

.forget_pass {
  color: $button_blue;
  text-decoration: underline;
}

@include media(sm) {
  .login_container {
    padding: 24px;
    background-color: $white;

    .input {
      margin: 0 auto 10px;
    }
  }
}

.address_intro {
  font-size: min(1.8rem, 18px);
  font-weight: bold;
  color: #555;
}

.mail_address_login {
  padding: 30px 0 20px;
  border-top: 1px solid #ccc;

  @include media(lg) {
    padding: 24px 0 8px;
  }
}

.navigate_area {
  max-width: 1000px;
  padding-bottom: 80px;
  margin: 0 auto;
}

.navigate_item {
  max-width: 740px;
  margin: 0 auto;
  text-align: center;

  @include media(lg) {
    display: block;
  }
}

.navigate_item_condition {
  margin-bottom: 1em;
  font-size: min(1.8rem, 18px);
  font-weight: bold;
  color: #555;

  @include media(lg) {
    font-size: min(2rem, 20px);
    text-align: center;
  }
}

.chat_navigation_button {
  display: table;
  padding: 16px 40px;
  margin: 0 auto;
  font-size: min(2rem, 20px);
  font-weight: 500;
  line-height: 35px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.05em;
  background: $public_button_green;
  border-radius: 90px;

  @include hover_color($public_button_hover_green);

  @include media(lg) {
    font-size: min(1.6rem, 16px);
    line-height: 1.6;
  }
}

.illust_wrap {
  width: 152px;

  svg {
    width: 100%;
  }

  @include media(lg) {
    width: 80px;
    margin: 0 auto 12px;
  }
}

.line_button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88%;
  max-width: 300px;
  height: 48px;
  margin: 0 auto;
  margin-bottom: 20px;
  overflow: hidden;
  font-size: min(1.6rem, 16px);
  font-weight: 500;
  line-height: 23px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.05em;
  cursor: pointer;
  background: #06c755;
  border-radius: 90px;

  .line_button_overlay {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;

    @media (hover: hover) {
      background: rgba(#000, 0);
      transition: 0.25s ease-out background;
    }
  }

  .line_icon_wrap,
  .line_button_text {
    position: relative;
    z-index: 3;
  }

  &:hover {
    .line_button_overlay {
      background: rgba(#000, 0.1);
    }
  }

  &:focus {
    .line_button_overlay {
      background: rgba(#000, 0.3);
    }
  }
}

.line_icon {
  width: 32px;
}

.line_icon_wrap {
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}

.line_button_text {
  padding: 0 8px;
}

.error_message {
  font-weight: bold;
  color: $alert_text_red;
  text-align: left;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
