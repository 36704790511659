
    @use '@variables_common' as *;
    
@use '@variables_iqfu' as *;

.passwordInput {
  position: relative;
  width: 100%;

  .inputText {
    width: 100%;
  }

  .eye {
    position: absolute;
    top: 50%;
    right: 0;
    left: auto;
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transform: translateY(-50%);

    .svg {
      display: block;
      width: 100%;
    }
  }

  &:global(.h48) {
    height: 48px;

    .inputText {
      height: 100%;
      text-align: left;
    }
  }
}
