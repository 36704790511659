$main_blue: #217fc1;
$placeholder_text_color: rgba(68, 68, 68, 0.7);

//breakpoints
$breakpoints: (
  'ssm': 374px,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1239px,
  'xxl': 1400px,
);

@mixin media($size) {
  @media screen and (max-width: #{map-get($breakpoints, $size)}) {
    @content;
  }
}

@mixin media_min($size) {
  @media screen and (min-width: #{map-get($breakpoints, $size)}) {
    @content;
  }
}

@mixin hoverable() {
  @media (hover: hover) {
    @content;
  }
}

@mixin form_reset_common {
  padding: 0.3em 1em;
  font-size: min(1.6rem, 20px);
  line-height: 1.5;
  color: #444;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  box-shadow: none;

  &:focus {
    border: 1px solid $main_blue;
  }

  &::placeholder {
    color: $placeholder_text_color !important;
  }
}

@mixin button_shadow {
  box-shadow: 1px 2px 4px #00000029;
}

@mixin hover_color($hover_color) {
  @include hoverable {
    &:hover {
      background: $hover_color;
      transition: 0.25s ease-out background;
    }
  }
}
