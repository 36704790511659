
    @use '@variables_common' as *;
    
.container {
  position: relative;
  padding-top: 80px;
  overflow: hidden;
  line-height: 1.8;

  @include media(lg) {
    padding-top: 44px;

    &.is_in_mobile_talk_room {
      padding-top: 0;
    }
  }

  &.sp_record {
    @include media(lg) {
      padding-top: 60px;
    }
  }
}
