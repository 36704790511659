
    @use '@variables_common' as *;
    
@use '@variables_iqfu' as *;

.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 400;
  width: 96px;
  height: 100%;
  //fixed headerの高さ分 padding-top
  padding-top: 80px;
  background: rgba(249, 249, 249, 1);

  &.isInPublicPage {
    //xlからlgの間でだけ、メインコンテンツの表示を阻害するので非表示
    @include media(xl) {
      display: none;
    }

    @include media(lg) {
      display: block;
    }
  }

  @include media(lg) {
    position: fixed;
    top: auto;
    bottom: 0;
    display: block;
    width: 100%;
    height: auto;
    padding: 0;
  }
}

.nav_list {
  display: flex;
  flex-flow: column;
  gap: 32px;
  justify-content: center;
  padding-top: 24px;

  @include media(lg) {
    flex-flow: row;
    gap: 0;
    padding-top: 0;
  }
}

.nav_item {
  text-align: center;

  a {
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  @include media(lg) {
    width: 25%;

    a {
      padding: 12px 0;
    }
  }
}

.nav_item_text {
  margin-top: 0.3em;
  font-size: 1.4rem;
  line-height: 1.3;
  color: #666;

  @include media(lg) {
    font-size: 1.2rem;
  }
}

.icon_wrapper {
  position: relative;
  line-height: 0;

  @include media(lg) {
    height: 20px;
  }

  img {
    height: 100%;
  }
}

.active_icon {
  position: absolute;
  top: 0;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
}

.chat_menu_link {
  &.is_active {
    .active_icon {
      opacity: 1;
    }
  }

  @include hoverable {
    .active_icon {
      transition: 0.3s;
    }

    &:hover {
      .active_icon {
        opacity: 1;
      }
    }
  }
}

.unread_icon {
  position: absolute;
  right: 65%;

  .icon_wrapper & {
    height: 40px;
  }

  transform: translate(200%, -50%);
}

.pc_only {
  @include media(lg) {
    display: none;
  }
}
