
    @use '@variables_common' as *;
    
@use '@variables_iqfu' as *;

.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  background: #fff;
}

.header {
  position: relative;
  z-index: 11;
  display: flex;
  align-items: center;
  height: 80px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

  @include media(xl) {
    height: auto;
    padding: 8px 12px;
  }

  @include media(lg) {
    &.sp_record {
      padding: 19px 10px;
    }
  }
}

.header_inner {
  display: flex;
  align-items: center;
  padding: 0 24px;
  margin: 0 auto;
  line-height: 0;

  @include media(xl) {
    justify-content: space-between;
    width: 100%;
    padding: 0;
  }
}

.desktop_nav {
  min-width: 670px;

  @include media(xl) {
    display: none;
  }
}

.header_logo {
  margin-right: 40px;

  @include media(xl) {
    width: 120px;
    margin-right: 24px;

    img {
      width: 100%;
    }
  }
}

.nav_list {
  display: flex;
  flex-shrink: 0;
  gap: 0 40px;
  min-width: 674px;

  @media screen and (max-width: 1440px) {
    gap: 0 20px;
  }

  @include media(xl) {
    display: none;
  }
}

@mixin nav_item_base {
  position: relative;
  display: flex;
  align-items: center;
  font-size: min(1.5rem, 20px);
  color: #666;

  &::before {
    position: absolute;
    bottom: -4px;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    content: '';
    background: $brand_orange;
    transition: 0.3s;
    transform: scaleX(0);
    transform-origin: left;
  }

  @media screen and (max-width: 1440px) {
    font-size: min(1.4rem, 20px);
  }
}

.nav_item {
  @include nav_item_base;

  &:hover {
    &::before {
      transform: scaleX(1);
    }
  }
}

.nav_item_with_dropdown {
  @include nav_item_base;

  &::after {
    display: inline-block;
    width: 9px;
    height: 6px;
    margin-top: 4px;
    margin-left: 4px;
    content: '';
    background: url('/images/iqfu/public/nav_arrow.svg');
    background-size: contain;
  }

  &.is_active {
    &::before {
      transform: scaleX(1);
    }
  }
}

.nav_item_without_dropdown {
  @include nav_item_base;
}

.mobile_only_auth_content_link {
  margin-right: 40px;

  .auth_gray_button_mobile,
  .auth_green_button_mobile {
    padding: 8px 16px;
    font-size: min(1.3rem, 16px);
  }
}

.media_link_container {
  margin-bottom: 16px;
}

.media_nav_top {
  margin-bottom: 8px;
  font-weight: bold;
  line-height: 1;
  color: #333;
  opacity: 0;
}

@mixin nav_auth_desktop {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 24px;
  font-size: min(1.5rem, 20px);
  line-height: 1;
  border-radius: 20px;

  @media screen and (max-width: 1440px) {
    font-size: min(1.4rem, 20px);
  }
}

.signup_button_desktop,
.chat_button_desktop {
  @include nav_auth_desktop;
  @include hover_color($public_button_hover_green);

  color: #fff;
  background: $public_button_green;
  border: 1px solid $public_button_green;
}

.login_button_desktop,
.logout_button_desktop {
  @include nav_auth_desktop;

  color: #666;
  border: 1px solid #ccc;
}

.auth_content {
  display: flex;
  gap: 0 8px;

  @media screen and (max-width: 1640px) {
    flex-shrink: 0;
    padding-right: 40px;
  }

  @media screen and (min-width: 1640px) {
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
  }

  @include media(xl) {
    display: none;
  }
}

.drop_down_container {
  position: absolute;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  width: 100%;
  pointer-events: none;
  transition: 0.5s;
  transform: translateY(0);

  &.is_active {
    pointer-events: auto;
    opacity: 1;
    transform: translateY(100%);
  }
}

.drop_down_menu {
  display: none;
  justify-content: center;
  width: 100%;
  padding: 40px 0;
  pointer-events: none;
  background: #fff;
  border-bottom: 1px solid #ddd;
  opacity: 0;

  &.is_active {
    display: flex;
    pointer-events: auto;
    opacity: 1;

    &.drop_down_menu_companies {
      flex-wrap: wrap;
      gap: 8px 0;

      .nav_link_list {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }

    .nav_link_item,
    .media_nav_top {
      animation-name: dropDownLinkFadeIn;
      animation-duration: 0.3s;
      animation-delay: 0.15s;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes dropDownLinkFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.nav_link_list {
  display: flex;
  gap: 0 28px;
}

.nav_link_item {
  position: relative;
  display: flex;
  gap: 0 4px;
  align-items: center;
  font-size: 1.5rem;
  color: #666;
  opacity: 0;
  transition: 0;
  transition-delay: 0;

  a {
    //アニメーション時のちらつき防止
    backface-visibility: hidden;
  }

  &:hover {
    &::after {
      transform: scaleX(1);
    }
  }

  &::after {
    position: absolute;
    bottom: -4px;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    content: '';
    background: $brand_orange;
    transition: transform 0.3s;
    transform: scaleX(0);
    transform-origin: left;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: -4px;
    display: inline-block;
    width: 5px;
    height: 2px;
    content: '';
    background: $brand_orange;
    transform: translate(-100%, -50%);
  }
}

.mobile_menu_toggle {
  display: none;

  @include media(xl) {
    position: fixed;
    top: 12px;
    right: 12px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    &.sp_record {
      display: none;
    }

    &.is_active {
      .mobile_menu_toggle_line {
        &:first-of-type {
          top: 50%;
          left: 50%;
          opacity: 1;
          transform: translate(-50%, -50%) rotate(225deg);
        }

        &:nth-of-type(2) {
          opacity: 0;
        }

        &:last-of-type {
          top: 50%;
          left: 50%;
          opacity: 1;
          transform: translate(-50%, -50%) rotate(-225deg);
        }
      }
    }
  }
}

.mobile_menu {
  display: none;
  overflow: scroll;

  @include media(xl) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    display: none;
    width: 100%;
    pointer-events: none;
    background: #fff;
    opacity: 0;
    transition: 0.5s;

    &.is_active {
      pointer-events: all;
      opacity: 1;
    }

    &.has_initialized {
      display: block;
    }
  }
}

@keyframes mobileMenuFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mobileMenuFadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.mobile_menu_toggle_line {
  display: inline-block;
  width: 18px;
  height: 2px;
  background: #555;
  transition: 0.3s;

  &:first-of-type {
    position: absolute;
    top: 50%;
    left: 50%;
    background: #555;
    transform: translate(-50%, calc(-50% - 6px));
    transform-origin: center center;
  }

  &:last-of-type {
    position: absolute;
    top: 50%;
    left: 50%;
    background: #555;
    transform: translate(-50%, calc(-50% + 6px));
    transform-origin: center center;
  }
}

.mobile_auth_button_container {
  display: flex;
  gap: 0 16px;
  justify-content: center;
  padding: 56px 20px 24px;
  border-bottom: 1px solid #ccc;

  .mobile_nav_container {
    padding: 12px 12px;
  }

  .auth_green_button_mobile {
    padding: 12px;
    font-size: min(1.4rem, 16px);
  }

  a,
  button {
    width: 50%;
  }
}

@mixin nav_auth_mobile {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  text-align: center;
  border-radius: 32px;
}

.auth_green_button_mobile {
  @include nav_auth_mobile;

  @include media_min(xl) {
    display: none;
  }

  color: #fff;
  background: $public_button_green;
  border: 1px solid $public_button_green;
}

.auth_gray_button_mobile {
  @include nav_auth_mobile;

  @include media_min(xl) {
    display: none;
  }

  color: #666;
  border: 1px solid #ccc;
}

.mobile_nav_container {
  padding: 4px 20px;

  * {
    line-height: 1.5;
  }
}

.mobile_nav_toggle {
  position: relative;
  display: block;
  padding: 16px 0;
  font-weight: bold;
  line-height: 1;
  color: rgba(85, 85, 85, 1);
  cursor: pointer;

  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 14px;
    height: 30px;
    content: '';
    background: url('/images/iqfu/public/mobile_nav_sidearrow.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    transition: 0.3s;
    transform: translateY(-50%);
  }

  &.is_active {
    &::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.mobile_nav_single {
  display: block;
  padding: 16px 0;
  font-weight: bold;
  color: rgba(85, 85, 85, 1);
  cursor: pointer;
}

.mobile_nav_block {
  border-bottom: 1px solid #ddd;
}

.mobile_nav_list {
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  padding: 0 0 20px;
}

.mobile_nav_item {
  position: relative;
  display: flex;
  align-items: center;
  font-size: min(1.4rem, 20px);
  color: #666;

  &::after {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-top: 4px;
    margin-left: 4px;
    content: '';
    background: url('/images/iqfu/public/nav_arrow.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    transform: rotate(-90deg);
  }
}

.mobile_media_nav_top {
  margin-bottom: 8px;
  font-size: min(1.5rem, 20px);
}

.liked_count {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 6px;
  font-size: 1.4rem;
  line-height: 1;
  color: #fff;
  background: #ffa0c2;
  border-radius: 80px;
  transform: translateY(-1px);
}
