//他ファイルから読み込まれるscssファイルのみ@variables_common明示的にimport(それ以外はwebpackで自動挿入される)
@use '@variables_common' as *;
// common
$white: #fff;
$table_2n_background: #f9f9f9;
$brand_orange: #fe9210;
$brand_orange_shadow: rgba(183, 105, 12, 1);
$brand_orange_hover: darken(#fe9210, 6%);
$disabled_color: rgba(6, 6, 6, 0.6);
$button_blue: #3b83c3;
$button_blue_hover: darken($button_blue, 6%);
$button_green: #4aa54d;
$button_green_hover: darken($button_green, 6%);
$button_yellow: #ffa907;
$button_yellow_hover: darken($button_yellow, 6%);
$button_red: #d62c45;
$button_red_hover: darken($button_red, 6%);
$button_purple: #a73baf;
$button_purple_hover: darken($button_purple, 6%);
$button_gray: #999;
$button_gray_hover: darken($button_gray, 6%);

//ui-color
$ui_active_red: #f51e58;
$link_text_blue: #2a64c5;
$alert_text_red: #e91212;
$dashboard_divider: #eee;
$cv_theme_red: #f00;

//Public
$public_button_green: rgba(23, 198, 156, 1);
$public_button_green_shadow: rgba(14, 124, 97, 1);
$public_button_hover_green: darken($public_button_green, 6%);
$public_line_green: #12cb46;
$public_line_green_hover: darken($public_line_green, 6%);
$public_like_button_hover_gray: darken(#fff, 6%);
$public_like_heart_pink: rgba(255, 160, 194, 1);
$public_like_heart_pink_hover: rgba(255, 160, 194, 0.6);
$public_buttoon_white: #fff;
$public_buttoon_hover_white: darken(#fff, 6%);

@mixin button_base($width: auto, $padding: 1em, $margin: 0 auto, $font-size: 1.6rem) {
  position: relative;
  width: $width;
  padding: $padding;
  margin: $margin;
  font-size: $font-size;
  line-height: 1;
  color: #fff;
  text-align: center;
  cursor: pointer;

  @include button_shadow;
}

/* disabled button */
@mixin inactive {
  pointer-events: none;
  background: #ccc;
  opacity: 0.6;
}

@mixin yellow_label {
  @include media_min(lg) {
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      height: 50%;
      content: '';
      background: #fcff75;
    }
  }

  @include media(lg) {
    background: linear-gradient(transparent 60%, #fcff75 0%);
  }
}

@mixin public_green_button($font-size: 1.8rem, $padding: 16px 40px) {
  padding: $padding;
  font-size: $font-size;
  font-weight: normal;
  line-height: 1;
  color: #fff;
  letter-spacing: 0.05em;
  background: $public_button_green;
  border-radius: 90px;

  @include hover_color($public_button_hover_green);

  @include media(lg) {
    font-size: 1.6rem;
  }
}

@mixin public_white_button($font-size: 1.8rem, $padding: 10px 40px) {
  padding: $padding;
  font-size: $font-size;
  font-weight: normal;
  color: #666;
  letter-spacing: 0.05em;
  background: $public_buttoon_white;
  border: 1px solid #ccc;
  border-radius: 90px;

  @include hover_color($public_buttoon_hover_white);

  @include media(lg) {
    font-size: 1.6rem;
  }
}

@mixin error_text {
  display: block;
  margin-top: -8px;
  margin-left: 240px;
  color: $alert_text_red;
}

@mixin section_lead_margin_bottom {
  margin-bottom: 32px;

  @include media(lg) {
    margin-bottom: 12px;
  }
}

@mixin saleCase_lead_container {
  margin-bottom: 16px;

  @include media(lg) {
    margin-bottom: 12px;
  }
}

@mixin area_links_list_font_style {
  display: inline-flex;
  align-items: center;
  padding: 8px 4px;
  font-size: min(1.4rem, 16px);
  font-weight: bold;
  line-height: 1.2;
  color: #2a64c5;
  text-align: left;
  border-right: 1px solid #efefef;
  border-bottom: 1px solid #efefef;

  &:hover {
    text-decoration: underline;
  }
}
