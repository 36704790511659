
    @use '@variables_common' as *;
    
@use '@variables_iqfu' as *;
@use '../dialog_common.module.scss' as *;

.dialog_inner {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;

  @include media(lg) {
    padding: 24px 12px;
  }
}

.content {
  font-size: 1.8rem;

  @include media(lg) {
    font-size: 1.6rem;
    text-align: left;
  }
}

.contact_link {
  font-weight: bold;
  color: $link_text_blue;
  text-decoration: underline;
}
